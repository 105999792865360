export const CONTRACT_FEATURES = {
  BLOCK_COLLABORATION: 'BLOCK_COLLABORATION',
  BLOCK_COLLABORATION_AFTER_N_MONTHS: 'BLOCK_COLLABORATION_AFTER_N_MONTHS',

  // Neximer is allowed to use AI to create a property description
  PROPERTY_DESCRIPTION_WITH_IA: 'PROPERTY_DESCRIPTION_WITH_IA',

  // Neximer is allowed to listing the property on the market on demand
  PROPERTY_HIGHLIGHT_ON_DEMAND: 'PROPERTY_HIGHLIGHT_ON_DEMAND',


  // Neximer is allowed to see contract profile section
  PROFILE_HIDE_CONTRACTS_SECTION: 'PROFILE_HIDE_CONTRACTS_SECTION',

  // Neximer is allowed to use AI to improves a property's images
  IMAGE_PROCESSING_WITH_AI: 'IMAGE_PROCESSING_WITH_AI',

  // Allow access to the email marketing app
  MARKETING_MODULE: 'MARKETING_MODULE',

  // Allows access to reward pages
  SHOW_REWARDS_MENU_ITEM: 'SHOW_REWARDS_MENU_ITEM',
}
